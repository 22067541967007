<template>
    <div class="footer">
        <div class="footer__row">
            <div>Работаем по всей России</div>
            <a href="tel:88007075519" class="footer__link">8-800-707-55-19</a>
        </div>
        <button class="btn btn-white btn-white--tr" @click.prevent="isShowModal = true">
            Получить консультацию
        </button>
    </div>
    <template v-if="isShowModal">
        <BaseModal :classes="'modal--question'" v-if="!isSendRequest" @close="isShowModal = false">
            <div class="modal__title subtitle js-error-modal-title">Есть вопросы?</div>
            <div class="modal__description js-error-modal-description">
                Наши специалисты помогут вам и ответят на все вопросы
            </div>
            <div class="modal__form form-modal">
                <div class="form-modal__fields">
                    <Input
                        :type="'text'"
                        :label="'Ваши имя'"
                        :code="'name'"
                        :error="errors.name"
                        :is-required="true"
                        v-model="questionInfo.name"
                    />

                    <div class="form-modal__row">
                        <Input
                            :type="'tel'"
                            :label="'Tелефон'"
                            :code="'phone'"
                            :error="errors.phone"
                            :is-required="true"
                            v-model="questionInfo.phone"
                        />
                        <Input
                            :type="'email'"
                            :label="'E-mail'"
                            :code="'email'"
                            :error="errors.email"
                            :is-required="true"
                            v-model="questionInfo.email"
                        />
                    </div>
                    <div data-input-group="question" class="input-group" :class="{ 'input-group--error': errors.question }">
                        <div class="input-group__label">Комментарий *</div>
                        <textarea name="question" class="input textarea" v-model="questionInfo.question"></textarea>
                        <div class="input-group__error" v-if="errors.question">{{ errors.question[0] }}</div>
                    </div>
                </div>
                <div class="form-modal__submit">
                    <button class="btn btn-red" @click.prevent="sendRequest">Получить консультацию</button>
                    <div class="text-gray">Нажимая на кнопку «Получить консультацию», я даю согласие на&nbsp;
                        <a href="/personal-data-policy" target="_blank">обработку персональных данных</a>
                    </div>
                </div>
            </div>
        </BaseModal>
        <ModalMessage v-else-if="!isSendError" @close="modalMessageClose" :message="messageSuccess" :isSuccess="true" />
        <ModalMessage v-else-if="isSendError" @close="modalMessageClose" :message="messageError" :isSuccess="false" />
    </template>
</template>

<script>
import BaseModal from "@/components/modals/base-modal";
import ModalMessage from "@/components/modals/modal-message";
import Input from '@/components/form/input';

export default {
    components: {
        BaseModal,
        ModalMessage,
        Input,
    },
    data() {
        return {
            isShowModal: false,
            questionInfo: {
                name: '',
                phone: '',
                email: '',
                question: '',
            },
            isSendRequest: false,
            isSendError: false,
            messageSuccess: {
                title: 'Ваш вопрос отправлен',
                description: 'Наши специалисты в скором времени ответят вам по электронной почте',
            },
            messageError: {
                title: 'Ваш вопрос не отправлен',
                description: 'Возникли неполадки с интернетом, пожалуйста, повторите попытку позже',
            },
            errors: {}
        }
    },
    methods: {
        sendRequest() {
            this.errors = {}

            for (let key in this.questionInfo) {
                if (!this.questionInfo[key])
                    this.errors[key] = ['Это поле обязательно для заполнения']
                else if (key === 'email') {
                    // eslint-disable-next-line
                    const isEmailCorrect = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.questionInfo.email)
                    if (!isEmailCorrect) this.errors.email = ['Введите корректный e-mail']
                }
            }

            if (Object.keys(this.errors).length == 0) {
                this.$store.dispatch('SEND_QUESTIONS', this.questionInfo)
                .then(() => this.isSendRequest = true)
                .catch(() => {
                    this.isSendError = true
                    this.isSendRequest = true
                })
            }
        },
        modalMessageClose() {
            this.isShowModal = false
            this.isSendError = false
            this.isSendRequest = false
        }
    },
    watch: {
        isShowModal() {
            this.errors = {}
        }
    }
}
</script>
